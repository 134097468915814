import type { ImagePlaceholder, ImagePlaceholderData, ImagePlaceholderPageConfig } from './types'
import { IComponentPropsExtender } from 'feature-components'
import { IMAGE_PLACEHOLDER_COMPONENTS_TYPES } from './imagePlaceholderComponentTypes'

export default function createFactory(imageClientApi: any) {
	return (
		pageConfig: ImagePlaceholderPageConfig
	): IComponentPropsExtender<
		{ getPlaceholder: (imagePlaceholderData: ImagePlaceholderData) => ImagePlaceholder },
		unknown
	> => {
		const { isSEOBot, isSeoExpOpen, staticMediaUrl } = pageConfig

		const getPlaceholder = ({ fittingType, src, target }: ImagePlaceholderData): ImagePlaceholder => {
			const placeholder = imageClientApi.getPlaceholder(fittingType, src, target, {
				isSEOBot: isSEOBot || isSeoExpOpen,
			})

			if (placeholder && placeholder.uri) {
				const uriWithFormatOverride = isSeoExpOpen
					? placeholder.uri.replace(/(\.jpg|\.jpeg|\.png)$/, '.webp')
					: placeholder.uri
				placeholder.uri = `${staticMediaUrl}/${uriWithFormatOverride}`
			}

			return placeholder
		}

		return {
			componentTypes: IMAGE_PLACEHOLDER_COMPONENTS_TYPES,
			getExtendedProps: () => ({ getPlaceholder }),
		}
	}
}
